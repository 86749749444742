import React from "react"
import ScrollMagic from "scrollmagic"
import { appearAnimation, bubble } from "../animations"

class TextColumns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: this.props.columns,
      modifier: this.props.modifier,
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller()

    Array.from(document.querySelectorAll(".text-column")).forEach(el => {
      new ScrollMagic.Scene({
        triggerElement: el,
        offset: -200,
      })
        .on("enter", () => {
          appearAnimation(el.children[0])
          appearAnimation(el.children[1], 0.1)
        })
        .addTo(controller)
    })
  }

  render() {
    return (
      <div className={"text-columns " + this.state.modifier}>
        {this.state.columns.map(col => {
          return (
            <div className="text-column" key={col.title}>
              {col.title && (
                <h3
                  className="appear"
                  onMouseEnter={e => {
                    bubble(e.target.children[0], e.target)
                  }}
                >
                  <span className="bubble" />
                  {col.title}
                </h3>
              )}
              {col.text && <p className="appear">{col.text}</p>}
            </div>
          )
        })}
      </div>
    )
  }
}

export default TextColumns
