import React from "react"
import Lottie from "react-lottie"
import Multipoint from "../visuals/multipoint.json"
import Search from "../visuals/search.json"
import Shapes from "../visuals/shapes.json"

export default class Animation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStopped: false,
      isPaused: false,
    }
  }

  selectVisual(selection) {
    let selectedVisual

    switch (selection) {
      case "shapes":
        selectedVisual = Shapes
        break
      case "search":
        selectedVisual = Search
        break
      default:
        selectedVisual = Multipoint
        break
    }

    return selectedVisual
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: this.selectVisual(this.props.visual),
      rendererSettings: {
        preserveAspectRation: "xMidYMid slice",
      },
    }

    return (
      <div className="animation">
        <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
      </div>
    )
  }
}
