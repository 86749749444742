import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ContactList from "./ContactList"
import CTABtn from "./CTABtn"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"
import gsap from "gsap"
import { isMobile } from "react-device-detect"

class CTA extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({
      triggerElement: "#cta",
    })
      .on("enter", () => {
        appearAnimation(".cta__left")
        appearAnimation(".cta__right")
        if (!isMobile) {
          gsap.to(".cta__separator__line", 0.8, {
            delay: 0.25,
            scaleY: 1,
            ease: "Power2.easeInOut",
          })
          gsap.to(".cta__separator__point", 0.7, {
            delay: 0.2,
            opacity: 1,
          })
        }
      })
      .addTo(controller)
  }

  render() {
    const CTAIndex = this.state.data.allWordpressPage.nodes.findIndex(
      el =>
        el.polylang_current_lang.split("_")[0] === this.props.lang &&
        el.template === "TemplateHome.php"
    )
    const CTA = this.state.data.allWordpressPage.nodes[CTAIndex].acf.cta
    const contactList = this.state.data.allWordpressPage.nodes[CTAIndex].acf
      .contact_info.contact_list

    const ctaBtn = this.state.data.allWordpressPage.nodes[CTAIndex].acf
      .contact_info.btn

    const btnColor = this.props.modifier.includes("cta--black")
      ? "white"
      : "blue"


    return (
      <section id="cta" className={this.props.modifier}>
        <div className="grid">
          <div className="row row--ver-middle">
            {CTA.headline && (
              <div className="col lg-4-12 lg-push-left-1-12 md-4-12 sm-12-12">
                <div className="cta__left appear">
                  <h2 dangerouslySetInnerHTML={{ __html: CTA.headline }} />
                  <div className="cta__left__btn cta__left__btn--mobile">
                    <CTABtn
                      btn={ctaBtn}
                      color={btnColor}
                      lang={this.props.lang}
                    />
                  </div>

                  <div className="cta__separator sm-show">
                    <div className="cta__separator__line" />
                  </div>
                  <div className="cta__separator__line__point point point--big point--mobile sm-show" />
                </div>
              </div>
            )}
            <div className="col lg-5-12 lg-push-left-2-12 md-7-12 md-push-left-1-12 sm-12-12">
              <div className="cta__right appear">
                {CTA.text && (
                  <div className="cta__right__text">
                    <p dangerouslySetInnerHTML={{ __html: CTA.text }} />
                  </div>
                )}
                <div className="cta__right__contacts">
                  <ContactList
                    modifier={
                      this.props.modifier.includes("cta--black")
                        ? "contact-list--white"
                        : null
                    }
                    data={contactList}
                    btn={ctaBtn}
                  />
                </div>
                <div className="cta__right__btn sm-hide">
                  <CTABtn
                    btn={ctaBtn}
                    color={btnColor}
                    lang={this.props.lang}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cta__separator__point point point--big" />
        </div>
        <div className="cta__separator md-hide">
          <div className="cta__separator__line" />
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query ctaQuery {
        allWordpressPage {
          nodes {
            wordpress_id
            template
            polylang_current_lang
            acf {
              cta {
                headline
                text
              }
              contact_info {
                contact_list {
                  label
                  type
                  email
                  phone
                }
                btn {
                  title
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <CTA data={data} {...props} />}
  />
)
