import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import TextColumns from "../components/TextColumns"
import CTA from "../components/CTA"
import Visuals from "../components/Visuals"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"

class SearchAndRescueSolutions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      pageContext: this.props.pageContext,
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller()

    const contentIntro = document.querySelector(".content__intro")

    new ScrollMagic.Scene({
      triggerElement: contentIntro,
      offset: -400,
    })
      .on("enter", () => {
        // Img
        appearAnimation(contentIntro.children[0])
        // h2
        appearAnimation(contentIntro.children[1].children[0])
        // p
        appearAnimation(contentIntro.children[1].children[1], 0.15)

        controller.destroy()
      })
      .addTo(controller)
  }

  render() {
    const searchAndRescue = this.state.data.wordpressPage.acf
      .content_search_rescue
    const enoughContent =
      searchAndRescue.intro_heading ||
      searchAndRescue.text_column_list.length > 1
        ? true
        : false

    return (
      <Layout
        lang={this.state.pageContext.lang}
        tpl={this.state.pageContext.templateGT}
        intro="parallaxed"
        introModifier="intro--search-rescue"
        pageContext={this.state.pageContext}
      >
        {enoughContent && (
          <section id="content" className="content--security content--search-rescue">
            <div className="grid">
              <div className="row">
                <div className="col lg-10-12 lg-push-left-1-12">
                  <div className="content__container">
                    {searchAndRescue.intro_heading && (
                      <div className="content__intro content__intro--split js-scroll-target">
                        <div className="content__intro__element img-box appear">
                          <Visuals visual="search" />
                        </div>
                        <div className="content__intro__text text-block">
                          <h2
                            className="appear"
                            dangerouslySetInnerHTML={{
                              __html: searchAndRescue.intro_heading,
                            }}
                          />
                          {searchAndRescue.intro_text && (
                            <p
                              className="appear"
                              dangerouslySetInnerHTML={{
                                __html: searchAndRescue.intro_text,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <TextColumns
                      columns={searchAndRescue.text_column_list}
                      modifier="text-columns--half"
                    />
                    {searchAndRescue.footnote && (
                      <p className="content__footnote">
                        {searchAndRescue.footnote}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <CTA
          modifier="cta--white"
          pageID={this.state.pageContext.id}
          lang={this.state.pageContext.lang}
        />
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          acf {
            content_search_rescue {
              intro_heading
              intro_text
              text_column_list {
                title
                text
              }
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <SearchAndRescueSolutions data={data} context={pageContext} {...props} />
    )}
  />
)
